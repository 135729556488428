<script setup lang="ts">
import type { CmsElementImageSlider, CmsElementImage } from "@shopware-pwa/composables-next";
import { SwiperOptions } from "swiper";

const props = defineProps<{
  content: CmsElementImageSlider;
}>();
const items = computed(() => props.content.data.sliderItems);

const paginationConfig: SwiperOptions["pagination"] = {
  enabled: true,
  clickable: true,
};
</script>
<template>
  <nuxt-error-boundary @error="() => {}">
    <Swiper
      :modules="[SwiperPagination]"
      :loop="true"
      :autoHeight="true"
      :slides-per-view="1"
      :space-between="5"
      :pagination="paginationConfig"
    >
      <SwiperSlide v-for="image of items" :key="image.media.url">
        <CmsElementImage :content="{ data: image, config: props.content.config } as unknown as CmsElementImage" />
      </SwiperSlide>
    </Swiper>
  </nuxt-error-boundary>
</template>
